import { template as template_bfd61e8ed00f4c7e93dd6f7fb800f11a } from "@ember/template-compiler";
const WelcomeHeader = template_bfd61e8ed00f4c7e93dd6f7fb800f11a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
